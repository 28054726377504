import type { GDataLayer } from '@demv_systems/feu-tag-manager';
import { useTagManager } from '@demv_systems/feu-tag-manager';
import { createPinia } from 'pinia';
import type { AsyncComponentLoader } from 'vue';
import { createApp, defineAsyncComponent } from 'vue';
import type { Router } from 'vue-router';

import './sentry';
import './app.css';
import type { MatomoTracking } from '@/global/tracking/types';

import FontAwesomeIcon from './icons';

type AsyncImport<T> = Promise<{ default: T }>;

interface Module<T> {
  component: AsyncComponentLoader<T>;
  router?: () => AsyncImport<Router>,
  pinia?: boolean,
}

const modules: Record<string, Module<any>> = {
  'digitalradar': {
    component: () => import('./modules/digitalradar/views/index.vue'),
    router: () => import('./modules/digitalradar/router'),
  },
  'inbox-settings': {
    component: () => import('./modules/inbox/views/index.vue'),
  },
  'ms-dashboard': {
    component: () => import('./modules/maklerbetreuerStatistics/views/index.vue'),
  },
  'beratungsvorsprung-lernnachweis': {
    component: () => import('./modules/beratungsvorsprung/lernnachweis/index.vue'),
    router: () => import('./modules/beratungsvorsprung/lernnachweis/router'),
  },
  'beratungsvorsprung-video': {
    component: () => import('./modules/beratungsvorsprung/video/views/index.vue'),
  },
  'faq': {
    component: () => import('./modules/faq/views/index.vue'),
    router: () => import('./modules/faq/router'),
  },
  'tarifierung': {
    component: () => import('./modules/tarifierung/views/index.vue'),
    router: () => import('./modules/tarifierung/router'),
  },
  'konfiguration': {
    component: () => import('./modules/konfiguration/views/index.vue'),
    router: () => import('./modules/konfiguration/router'),
    pinia: true,
  },
  'login': {
    component: () => import('./modules/login/views/index.vue'),
  },
  'partnerbogen-join': {
    component: () => import('./modules/partnerbogen/join.vue'),
  },
  'partnerbogen-banner': {
    component: () => import('./modules/partnerbogen/banner/index.vue'),
  },
  'startseite': {
    component: () => import('./modules/startseite/views/index.vue'),
    pinia: true,
  },
  'profil-finanzmanager': {
    component: () => import('./modules/profilFinanzmanager/views/index.vue'),
  },
  'profil-notification-settings': {
    component: () => import('./modules/profilNotifications/views/index.vue'),
  },
  'kooperation': {
    component: () => import('./modules/kooperation/views/index.vue'),
    router: () => import('./modules/kooperation/router'),
  },
  'infoPoint': {
    component: () => import('./modules/infoPoint/views/index.vue'),
    router: () => import('./modules/infoPoint/router'),
  },
  'bestandsuebertragungCoc': {
    component: () => import('./modules/bestandsuebertragungCoc/views/index.vue'),
  },
  'gesellschaften': {
    component: () => import('./modules/gesellschaften/views/index.vue'),
    router: () => import('./modules/gesellschaften/router'),
    pinia: true,
  },
  'extras': {
    component: () => import('./modules/extras/views/index.vue'),
    router: () => import('./modules/extras/router'),
  },
  'billing': {
    component: () => import('./modules/billing/views/index.vue'),
    router: () => import('./modules/billing/router'),
    pinia: true,
  },
  'brokerDashboard': {
    component: () => import('./modules/brokerDashboard/views/index.vue'),
    pinia: true,
  },
  'largefileupload': {
    component: () => import('@/modules/largefileupload/views/upload.vue'),
  },
  'marketplace': {
    component: () => import('./modules/marketplace/views/index.vue'),
    router: () => import('./modules/marketplace/router'),
    pinia: true,
  },
  'oauthConsent': {
    component: () => import('./modules/oauthConsent/views/consent.vue'),
  },
};

declare global {
  interface Window {
    insertVue3Component(
      module: string,
      brokerId: string,
      props?: Record<string, any> | null,
      notifications?: Record<string, string>
    ): void;
    env: string | undefined;
    _paq?: MatomoTracking;
    dataLayer: GDataLayer[]; // we pre define this in analytics.php
  }
}

window.insertVue3Component = async (
  module,
  brokerId,
  props,
  notifications,
) => {
  const mountEl = document.getElementById('app');

  if (!mountEl) {
    console.warn('Mounting element not found');
    return;
  }

  const instance = modules[module];

  if (instance === undefined) {
    throw new Error(`Unknown module: ${module}`);
  }

  const app = createApp(defineAsyncComponent(instance.component), props);

  if (instance.router !== undefined) {
    app.use((await instance.router()).default);
  }

  if (instance.pinia) {
    app.use(createPinia());
  }

  app.component('FontAwesomeIcon', FontAwesomeIcon);
  app.mount(mountEl);

  app.provide('System.notifications', notifications);

  if (process.env.NODE_ENV === 'development') {
    console.warn('Google Analytics tracking disabled');
  } else {
    useTagManager().init(brokerId, 'GTM-W84WT9R7');
  }

  document.getElementById('vue-loading-indicator')?.remove();
};
